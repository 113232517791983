import React from 'react';
import MarkdownIt from 'markdown-it';
import classNames from 'classnames/bind';
import isNullish from 'utilities/is-nullish';
import * as styles from './styles.module.scss';

const cx = classNames.bind(styles);

const md = new MarkdownIt({
  breaks: true,
});

const Poem = ({poem}) => {
  const {slug, title, words, footnote} = poem;

  return (
    <div className={cx('poem')} id={slug}>
      <h3 dangerouslySetInnerHTML={{ __html: md.renderInline(title) }} />
      <div dangerouslySetInnerHTML={{ __html: md.render(words) }} />
      { !isNullish(footnote) ?
        <div className={cx('poemFootnote')} dangerouslySetInnerHTML={{ __html: md.render(footnote) }} />
      : null }
    </div>
  );
}

export default Poem;