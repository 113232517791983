import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import VisibilitySensor from 'react-visibility-sensor';
import { Squash as Hamburger } from 'hamburger-react'
import MarkdownIt from 'markdown-it';
import SEO from 'components/seo';
import isNullish from 'utilities/is-nullish';
import Poem from 'components/poem';
import PageHeader from 'components/page-header';
import * as styles from './styles.module.scss';

const cx = classNames.bind(styles);
const md = new MarkdownIt({
  breaks: false,
});

class IndexTemplate extends React.PureComponent {

  static propTypes = {
    poems: PropTypes.array,
    content: PropTypes.object,
  };

  static defaultProps = {
    poems: [],
    content: {},
  };

  constructor(props) {
    super(props);

    this.pauseChanged = false;
    this.timeout = null;

    this.state = {
      currentPoemId: null,
      menuOpen: false,
    }
  }

  onChange = (visible, poem) => {
    this.setActiveListItem(poem.order);
  }

  setActiveListItem = (order) => {
    if (!this.pauseChanged) {
      this.setState({
        currentPoemId: order,
      }, () => {
        this.pauseChanged = true;
        this.timeout = window.setTimeout(() => {
          this.pauseChanged = false;
        }, 0);
      });
    }
  }

  onListItemClick = (poem) => {
    this.setActiveListItem(poem.order);
    this.setState({
      menuOpen: false,
    });
  }

  onClickMenuButton = (evt) => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  render() {
    const {poems, content} = this.props;
    const {currentPoemId, menuOpen} = this.state;

    if (typeof window !== 'undefined') {
      if (menuOpen) {
        window.document.body.style.overflow = 'hidden';
      }
      else {
        window.document.body.style.overflow = 'auto';
      }
    }

    return (
      <div className={cx('page')}>

        <SEO />

        <div className={cx('headerWrapper')}>
          <div className={cx('headerInner')}>
            <PageHeader
              content={content}
              theme='dark'
            />
          </div>
        </div>

        { !isNullish(content.foreword) ?
          <div className={cx('forewordWrapper')} id='foreword'>
            <VisibilitySensor
              onChange={ (visible) => {if (visible) { this.onChange(visible, {'order': 'foreword'})}} }
              delayedCall
              partialVisibility
              resizeCheck
              resizeDelay={ 250 }
              scrollThrottle={ 250 }
              offset={ { top: 200, bottom: 200 } }
            >
              <div className={cx('foreword')}>
                <h2>Foreword</h2>
                <div dangerouslySetInnerHTML={{ __html: md.render(content.foreword) }} />
              </div>
            </VisibilitySensor>
          </div>
        : null }

        <div className={cx('poemsWrapper')}>
          <div className={cx('poems')}>
            { poems.map((poem, idx) => {
              return (
                <React.Fragment key={idx}>
                   { poem.category ?
                    <h2>{poem.category}</h2>
                  : null }
                  <div className={cx('poemWrap')}>
                    <VisibilitySensor
                      onChange={ (visible) => {if (visible) { this.onChange(visible, poem)}} }
                      delayedCall
                      partialVisibility
                      resizeCheck
                      resizeDelay={ 250 }
                      scrollThrottle={ 250 }
                      offset={ { top: 200, bottom: 200 } }
                    >
                      <Poem
                        poem={poem}
                      />
                    </VisibilitySensor>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>

        <div className={cx('poemListWrapper', {'open': menuOpen})}>
          <div className={cx('inner')}>
            <div className={cx('content')}>

              <PageHeader
                content={content}
                theme='light'
              />

              { !isNullish(content.foreword) ?
                <a className={cx('linkForeword', {'active': currentPoemId === 'foreword'})} onClick={() => {this.setState({menuOpen: false})}} href="#foreword">Foreword</a>
              : null }

              { poems.length > 0 ?
                <ul>
                  { poems.map((poem, idx) => {
                    return (
                      <React.Fragment key={idx}>
                      { poem.category ?
                        <li className={cx('poemCategorykWrap')}>
                          <span>{poem.category}</span>
                        </li>
                      : null }
                        <li className={cx('poemLinkWrap')}>
                          <a className={cx({'active': poem.order === currentPoemId})} href={`#${poem.slug}`} onClick={() => {this.onListItemClick(poem)}}>{poem.title}</a>
                        </li>
                      </React.Fragment>
                    );
                  })}
                </ul>
              : null}
            </div>
          </div>
          <div className={cx('fade')} />
        </div>

        <div className={cx('menuWrapper')}>
          <div className={cx('menuInner')}>
            <button className={cx('menu')}>
              <Hamburger
                size='32'
                color={menuOpen ? '#fff' : '#333'} 
                toggled={menuOpen}
                toggle={this.onClickMenuButton}
              />
            </button>
          </div>
        </div>

      </div>
    );
  }
}

export default IndexTemplate;