import React from 'react';
import { graphql } from 'gatsby';
import IndexTemplate from '../templates/index';

const Index = ({data}) => {

  const poems = data.poems.nodes.map(({data}) => {
    return {
      ...data,
    };
  });

  const content = data.content.nodes.reduce((all, { data }) => {
    if (data) {
      const { key, value } = data;
      all[key] = value;
    }
    return all;
  }, {});

  // console.log(poems);
  // console.log(content);

  return (
    <IndexTemplate
      poems={poems}
      content={content}
    />
  );
}

export default Index;


export const query = graphql`
  query {
    poems: allAirtable(
      filter: {table: {eq: "poems"}}
      sort: {fields: data___order, order: ASC},
    ) {
      nodes {
        data {
          order
          slug
          title
          words
          category
          footnote
        }
      }
    }
    content: allAirtable(
      filter: {table: {eq: "content"}}
    ) {
      nodes {
        data {
          key
          value
        }
      }
    }
  }
`