// extracted by mini-css-extract-plugin
export const page = "styles-module--page--20Wqb";
export const forewordWrapper = "styles-module--forewordWrapper--wV-iu";
export const foreword = "styles-module--foreword--JIl0-";
export const poemListWrapper = "styles-module--poemListWrapper--2FGjF";
export const inner = "styles-module--inner--km9c9";
export const content = "styles-module--content--1oay2";
export const poemCategorykWrap = "styles-module--poemCategorykWrap--3o77y";
export const poemLinkWrap = "styles-module--poemLinkWrap--2mxOA";
export const linkForeword = "styles-module--linkForeword--1TKI5";
export const poemsWrapper = "styles-module--poemsWrapper--2WqAy";
export const poems = "styles-module--poems--3v95q";
export const menuWrapper = "styles-module--menuWrapper--SC9aM";
export const menuInner = "styles-module--menuInner--1VDGX";
export const menu = "styles-module--menu--1ucy8";
export const open = "styles-module--open--F4J8S";
export const active = "styles-module--active--1ENpa";
export const fade = "styles-module--fade--29luR";
export const hidden = "styles-module--hidden--22BnW";
export const headerWrapper = "styles-module--headerWrapper--1Ccl6";
export const headerInner = "styles-module--headerInner--jcE05";
export const poemWrap = "styles-module--poemWrap--EvM97";