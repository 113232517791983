import React from 'react';
import MarkdownIt from 'markdown-it';
import classNames from 'classnames/bind';
import { SocialIcon } from 'react-social-icons';
import * as styles from './styles.module.scss';

const cx = classNames.bind(styles);

const md = new MarkdownIt({
  breaks: false,
});

const PageHeader = (props) => {
  const {content, theme} = props;

  return (
    <div className={cx('pageHeader', theme)}>
      <h1 dangerouslySetInnerHTML={{ __html: md.renderInline(content.title) }} />
      <div className={cx('socialWrapper')}>
        <div className={cx('link')}>
          <SocialIcon url={content.social_link_instagram} network='instagram' bgColor='#777' fgColor='#343333' />
        </div>
        <div className={cx('link')}>
          <SocialIcon url={content.social_link_twitter} network='twitter' bgColor='#777' fgColor='#343333' />
        </div>
        <div className={cx('link')}>
          <SocialIcon url={content.social_link_facebook} network='facebook' bgColor='#777' fgColor='#343333' />
        </div>
        <div className={cx('link')}>
          <SocialIcon url={content.social_link_linkedin} network='linkedin' bgColor='#777' fgColor='#343333' />
        </div>
      </div>
    </div>
  );
}

export default PageHeader;